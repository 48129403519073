<template>
    <div class="px-page mt-5 mb-5">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">Konfigurieren</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">Bestellung prüfen</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">Abgeschlossen</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row class="ml-2 mr-2">
                        <v-col md="12">
                            Konfigurierbare Domains
                            <v-slider v-model="domainsOption" min="0" :max="domainsValues.length - 1" :tick-labels="domainsValues" tick-size="0" />
                        </v-col>
                        <v-col md="12">
                            Speicher
                            <v-slider v-model="diskOption" min="0" :max="diskValues.length - 1" :tick-labels="diskValues.map(e => e + ' GB')" tick-size="0" />
                        </v-col>
                        <v-col md="6">
                            Datenbanken
                            <v-slider v-model="databasesOption" min="0" :max="databasesValues.length - 1" :tick-labels="databasesValues" tick-size="0" />
                        </v-col>
                        <v-col md="6">
                            E-Mail Postfächer
                            <v-slider v-model="mailboxesOption" min="0" :max="mailboxesValues.length - 1" :tick-labels="mailboxesValues" tick-size="0" />
                        </v-col>
                        <v-col md="12">
                            Laufzeit
                            <v-select v-model="duration" :items="durationValues.map(v => ({ text: v + ' Tage', value: v }))" />
                        </v-col>
                        <v-col md="12" class="text-center" style="font-size: 25px; font-weight: 500;">
                            Preis: {{ this.price.toFixed(2).replace('.',',') }} €
                        </v-col>
                        <v-col md="12" class="text-center" style="font-size: 25px; font-weight: 500;">
                            <v-btn v-if="$store.state.current_user" large color="primary" @click="step = 2">Bestellung prüfen</v-btn>
                            <div v-else>
                                <v-btn large color="primary" @click="bus.$emit('login:show')">Anmelden</v-btn>
                                <v-btn large color="primary" @click="bus.$emit('register:show')" class="ml-2">Registrieren</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2" class="text-center">
                    <table style="width: 100%; border-collapse: collapse; font-size: 20px;" class="text-left">
                        <tbody>
                            <tr>
                                <td>Basispreis</td>
                                <td class="text-right">{{ priceDisplay('base', 1) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ domains }} Konfigurierbare Domains</td>
                                <td class="text-right">{{ priceDisplay('domains', domains) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ disk }} GB Speicher</td>
                                <td class="text-right">{{ priceDisplay('disk', disk) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ databases }} Datenbanken</td>
                                <td class="text-right">{{ priceDisplay('databases', databases) }} €</td>
                            </tr>
                            <tr>
                                <td>{{ mailboxes }} E-Mail Postfächer</td>
                                <td class="text-right">{{ priceDisplay('mailboxes', mailboxes) }} €</td>
                            </tr>
                            <tr>
                                <td style="border-top: 1px solid black;">Laufzeit</td>
                                <td style="border-top: 1px solid black;" class="text-right">{{duration}} Tage</td>
                            </tr>
                            <tr>
                                <td style="border-top: 1px solid black; padding-top: 5px;"><strong>Gesamt</strong></td>
                                <td style="border-top: 1px solid black; padding-top: 5px;" class="text-right"><strong>{{ priceDisplay('total') }} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    <v-btn color="primary" large class="mt-5 mr-2 mb-5" @click="step = 1" :disabled="ordering">Konfiguration ändern</v-btn>
                    <v-btn color="primary" large class="mt-5 mb-5" v-if="($store.state.current_user || { balance: 0 }).balance >= price" @click="order()" :disabled="ordering">Kostenpflichtig bestellen <v-progress-circular v-if="ordering" class="ml-2" indeterminate /></v-btn>
                    <v-btn color="primary" large class="mt-5 mb-5" v-else @click="bus.$emit('recharge:show')">Guthaben aufladen</v-btn>
                </v-stepper-content>
                <v-stepper-content step="3" class="text-center pt-5 pb-5">
                    Bestellung abgeschlossen<br><br>
                    <v-icon color="green" size="50">mdi-check</v-icon><br><br>
                    <v-btn color="primary" @click="$router.push({ name: 'dashboard-webspace', params: { id: webspace.id } })">Zum Webspace</v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    data() {
        return {
            step: 1,
            pricing: {
                base: 0,
                domains: 0,
                disk: 0,
                databases: 0,
                mailboxes: 0
            },
            domainsValues: [1,3,5,10,25],
            diskValues: [1,3,5,10,20,30,40,50,100],
            databasesValues: [3,5,10,15],
            mailboxesValues: [10,20,30,50,100],
            durationValues: [30,90,180,360],
            domainsOption: 0,
            diskOption: 0,
            databasesOption: 0,
            mailboxesOption: 0,
            duration: 30,
            ordering: false,
            webspace: {
                id: 0
            }
        }
    },
    created() {
        xeonserv().get('/pricing/WEBSPACE').then(res => this.pricing = res.data.data)
    },
    computed: {
        domains() {
            return this.domainsValues[this.domainsOption]
        },
        disk() {
            return this.diskValues[this.diskOption]
        },
        databases() {
            return this.databasesValues[this.databasesOption]
        },
        mailboxes() {
            return this.mailboxesValues[this.mailboxesOption]
        },
        price() {
            return (this.pricing.base + (this.domains * this.pricing.domains) + (this.disk * this.pricing.disk) + (this.databases * this.pricing.databases) + (this.mailboxes * this.pricing.mailboxes)) * (this.duration / 30)
        }
    },
    methods: {
        order() {
            this.ordering = true
            xeonserv().post('/webspaces', {
                domains: this.domains,
                disk: this.disk,
                databases: this.databases,
                mailboxes: this.mailboxes,
                duration: this.duration
            }).then(res => {
                this.webspace = res.data.data
                this.step = 3
                this.$store.dispatch('loadCurrentUser')
            }, () => this.ordering = false)
        },
        priceDisplay(prop, amount) {
            const p = prop == 'total' ? this.price : (amount * this.pricing[prop] * (this.duration / 30))
            return p.toFixed(2).replace('.', ',')
        }
    }
}
</script>